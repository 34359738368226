import React from 'react';
import '../styles/ProfileCard.css';

function Button(props) {
    return (
      <a href={props.redir} className="btn btn3">
        {props.name}
      </a>
    );
  }
  
  
  function ProfileCard() {
    return (

       <div className="card">
        <div className="flex margin-top">
          <img
            className="profile-img"
            src="https://res.cloudinary.com/nilay-patel-salesforce/image/upload/v1664368943/Personal%20Site/Images/Avatar/avatar_photo_wkniss.jpg"
            alt="Avatar"
          />   
        </div>
        <div className="container">
          <div className="inner">
            <h1 className="center">Nilay Patel</h1>
            <h2 className="center">Solution Engineer @ Salesforce</h2>
            <br />
          <a
                className="coffee-button-margin-bottom Absolute-Center"
                href="https://www.buymeacoffee.com/Nilay"
                >
                <img src="https://img.buymeacoffee.com/button-api/?text=Buy me a coffee&emoji=&slug=Nilay&button_colour=493627&font_colour=ffffff&font_family=Cookie&outline_colour=ffffff&coffee_colour=DDCA7D" alt=""></img></a>
          </div>
          <div className="flex">
            <div className="button-flex">
              <Button name="Twitter" redir="https://twitter.com/nilayvekaria"></Button>
              <Button name="LinkedIn" redir="https://www.linkedin.com/in/nilay-patel-profile"></Button>
              <Button name="Github" redir="https://www.github.com/patelnilay"></Button>
            </div>
          </div>
        </div>
       </div>
      );
  } 

export default ProfileCard;
