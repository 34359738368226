import React from "react";
import "../styles/ProjectsCard.css";
import gif from "../media/stay_tuned.gif";

function ProjectCard() {
  return (
    <div className="card project-card">
      <h2 className="project-title center">
        Project showcase coming soon! 😊😇
      </h2>
      <br />
      <div className="flex">
        <div className="button-flex">
          <img className="coming-soon-gif" src={gif} alt="Coming Soon" />
        </div>
      </div>
      <br />
      <h3 className="proj-placeholder-text center">
        👷 They're still under construction 🛠️
      </h3>
    </div>
  );
}

export default ProjectCard;
