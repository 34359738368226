import React, { useState } from "react";
import "../styles/CertificateFlow.css";
import adminCertImg from "../media/adminCertImg.png";
import advAdmin from "../media/advAdmin.png";
import appBuildCertImg from "../media/appBuildCertImg.png";

function CertButton({ showCerts, setShowCerts }) {
  return (
    <div className="flex">
      <div className="button-flex">
        <button
          onClick={() => {
            setShowCerts(!showCerts);
          }}
          className="btn button cert-btn"
        >
          <span>Click Me!</span>
        </button>
      </div>
    </div>
  );
}

function CertImages() {
  return (
    <div className="flex">
      <div className="button-flex">
        <img
          className="cert-img"
          src={adminCertImg}
          alt="Admin Cert Img"
        />
        <img className="cert-img" src={appBuildCertImg} alt="App Builder Img" />
        <img className="cert-img" src={advAdmin} alt="Advanced Admin Img" />
      </div>
    </div>
  );
}

function CertificateFlow() {
  const [showCerts, setShowCerts] = useState(false);
  return (
    <div className="card">
      <h2 className="cert-title">
        Psssssttt... Click to view my certifications 🤪
      </h2>
      <CertButton
        showCerts={showCerts}
        setShowCerts={setShowCerts}
      ></CertButton>
      {showCerts ? <CertImages /> : null}
    </div>
  );
}

export default CertificateFlow;
