import React from "react";
import ReactDOM from "react-dom";
import "./index.css";

import ProfileCard from './components/ProfileCard';
import CertificateFlow from './components/CertificateFlow';
import ProjectsCard from './components/ProjectsCard';


function App() {
  return (
    <div className="bodyContainer">
      <div className="cardContainer">
          <ProfileCard></ProfileCard>
      </div>
      <div className="cardContainer">
          <CertificateFlow></CertificateFlow>
      </div>
      <div className="cardContainer">
        <ProjectsCard></ProjectsCard>
      </div>
    </div>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));
